import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      d="M10.825 22.5q-.675 0-1.163-.45-.487-.45-.587-1.1L8.85 19.3a4 4 0 0 1-.613-.3 8 8 0 0 1-.562-.375l-1.55.65q-.625.275-1.25.05a1.84 1.84 0 0 1-.975-.8l-1.175-2.05a1.59 1.59 0 0 1-.2-1.225q.15-.65.675-1.075l1.325-1a2.4 2.4 0 0 1-.025-.338v-.675q0-.162.025-.337l-1.325-1a1.9 1.9 0 0 1-.675-1.075q-.15-.65.2-1.225L3.9 6.475q.35-.575.975-.8t1.25.05l1.55.65q.275-.2.575-.375t.6-.3l.225-1.65q.1-.65.587-1.1.488-.45 1.163-.45h2.35q.675 0 1.162.45.488.45.588 1.1l.225 1.65q.325.125.612.3.288.175.563.375l1.55-.65q.625-.275 1.25-.05.626.225.975.8l1.175 2.05q.35.575.2 1.225t-.675 1.075l-1.325 1q.025.176.025.338v.675q0 .162-.05.337l1.325 1q.525.425.675 1.075t-.2 1.225l-1.2 2.05q-.35.575-.975.8t-1.25-.05l-1.5-.65a7 7 0 0 1-.575.375q-.3.175-.6.3l-.225 1.65q-.1.65-.588 1.1-.487.45-1.162.45zm.175-2h1.975l.35-2.65q.775-.2 1.438-.588t1.212-.937l2.475 1.025.975-1.7-2.15-1.625q.125-.35.175-.738a6.2 6.2 0 0 0 0-1.575 3.5 3.5 0 0 0-.175-.737l2.15-1.625-.975-1.7-2.475 1.05a5.6 5.6 0 0 0-1.213-.962 5.6 5.6 0 0 0-1.437-.588L13 4.5h-1.975l-.35 2.65q-.775.2-1.438.587-.662.388-1.212.938L5.55 7.65l-.975 1.7 2.15 1.6q-.125.375-.175.75t-.05.8q0 .4.05.775t.175.75l-2.15 1.625.975 1.7 2.475-1.05q.55.575 1.212.962.663.388 1.438.588zm1.05-4.5q1.45 0 2.475-1.025A3.37 3.37 0 0 0 15.55 12.5q0-1.45-1.025-2.475A3.37 3.37 0 0 0 12.05 9q-1.474 0-2.488 1.025A3.4 3.4 0 0 0 8.55 12.5q0 1.45 1.012 2.475Q10.576 16 12.05 16"
    />
  </svg>
);
export default SvgSettingsIcon;
