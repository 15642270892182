'use client';

import classNames from 'classnames';
import { type ReactElement } from 'react';
import {
  type TagGroupProps,
  type TagListProps,
  Button,
  type Key,
  Label,
  Tag,
  TagGroup,
  TagList,
  Text,
} from 'react-aria-components';

import { Alert, AlertVariant } from '../alert';
import { CloseIcon } from '../svgs';

import styles from './tags-list.module.scss';

type TagItem = {
  id: number | string;
  name: string;
  url?: string;
};

type TagsListProps<T> = {
  additionalContentSlot?: ReactElement;
  centerContent?: boolean;
  description?: string;
  errorMessage?: string;
  items: TagItem[];
  label?: string | null;
  onRemove?: (keys: Set<Key>) => void;
} & Omit<TagGroupProps, 'children'> &
  Pick<TagListProps<T>, 'items' | 'children'>;

export const TagsList = <T extends object>({
  additionalContentSlot,
  centerContent = false,
  description,
  errorMessage,
  items,
  label,
  onRemove,
  ...rest
}: TagsListProps<T>) => {
  return (
    <TagGroup
      className={classNames(styles.tags, {
        [styles['tags--center-content']]: centerContent,
      })}
      {...(onRemove ? { onRemove: (keys) => onRemove(keys) } : {})}
      {...rest}
    >
      {label && (
        <Label className={styles['tags__label']} elementType="h2">
          {label}
        </Label>
      )}
      {description && (
        <Text className={styles['tags__description']} elementType="p" slot="description">
          {description}
        </Text>
      )}
      {errorMessage && (
        <Alert
          className={styles['tags__error-message']}
          message={errorMessage}
          variant={AlertVariant.Error}
        />
      )}
      <div className={styles['tags__list']}>
        {items.length > 0 && (
          <TagList>
            {items.map((item) => (
              <Tag
                className={({ isSelected }) =>
                  classNames(styles.tag, {
                    [styles['tag--theme-light']]: !isSelected,
                    [styles['tag--theme-active']]: isSelected,
                  })
                }
                id={item.id}
                key={item.id}
                textValue={item.name}
              >
                <>
                  {item.name}
                  {onRemove && (
                    <Button
                      className={styles['tag__icon-container']}
                      data-testid="tag-remove-button"
                      slot="remove"
                    >
                      <CloseIcon />
                    </Button>
                  )}
                </>
              </Tag>
            ))}
          </TagList>
        )}
        {additionalContentSlot}
      </div>
    </TagGroup>
  );
};
