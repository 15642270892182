import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBookmarkFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 3h10c1.1 0 2 .9 2 2v14.484a1 1 0 0 1-1.394.919L12 18l-5.606 2.403A1 1 0 0 1 5 19.483V5c0-1.1.9-2 2-2m3.825 8.175L13.65 8.35q.3-.3.713-.3.412 0 .712.3t.3.712-.3.713l-3.55 3.525q-.3.3-.7.3a.96.96 0 0 1-.7-.3L8.7 11.875a.93.93 0 0 1-.275-.687q0-.414.275-.713.3-.3.713-.3.412 0 .712.3z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBookmarkFilledIcon;
